import { inject, Injectable, signal, WritableSignal } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map, of, Subject, takeUntil } from 'rxjs';
import { Router } from '@angular/router';
import { LoginInterface } from '../../interfaces/auth/login-interface';
import { environment } from '../../../../environments/environment';
import { SelectCompanyInterface } from '../../interfaces/auth/select-company-interface';
import { StoreService } from '../../store/store.service';
import { LocalStorageService } from '../storage/local-storage.service';
import { AccountsService } from '../accounts/accounts.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  token$: WritableSignal<string | undefined> = signal(undefined);
  router = inject(Router);
  storeService = inject(StoreService);
  accountsService = inject(AccountsService);
  private destroy$ = new Subject<void>();

  authChannel = new BroadcastChannel('auth');

  constructor(
    private localStorageService: LocalStorageService,
    private http: HttpClient
  ) {
    const token = this.getToken();
    if (token) {
      this.token$.set(token);
    }

    this.authChannel.onmessage = (event) => {
      if (event.data === undefined) {
        this.logout(false);
      } else {
        this.setToken(event.data, false);

        this.accountsService
          .getCompanyByCompanyCode(true)
          .pipe(takeUntil(this.destroy$))
          .subscribe((companyResponse) => {
            this.router.navigate(['/']).then(() => {
              if (
                companyResponse &&
                companyResponse.Company &&
                +companyResponse.Company.RemainingTime > 0
              ) {
                window.location.reload();
              }
            });
          });
      }
    };
  }

  login = (email: string, password: string, Use2Fa: boolean) => {
    return this.http
      .post<LoginInterface>(`${environment.baseUrl}/accounts/login`, {
        email,
        password,
        Use2Fa,
      })
      .pipe(
        catchError(() => {
          return of({
            Result: {
              Status: false,
              Message: 'Giriş işlemi başarısız oldu',
              Data: [],
            },
          } as LoginInterface);
        }),
        map((f) => {
          return f;
        })
      );
  };

  selectCompany = (
    CompanyCode: string,
    Email: string,
    Password: string,
    UserId: string,
    SmsCode?: string
  ) => {
    return this.http
      .post<SelectCompanyInterface>(
        `${environment.baseUrl}/accounts/selectCompany`,
        {
          CompanyCode,
          Email,
          Password,
          UserId,
          SmsCode,
        }
      )
      .pipe(
        map((f) => {
          if (f.Result.Status) {
            this.setToken(f.Result.Data.Token, true);
          } else {
            this.token$.set(undefined);
          }

          return f;
        }),
        catchError(() => {
          return of({
            Result: {
              Status: false,
              Message: 'Şirket seçme işlemi başarısız oldu',
              Data: {
                CompanyCode: null,
                UserId: null,
                RedisKey: null,
                Token: '',
                MenuItems: {
                  Status: false,
                  Message: null,
                  Data: [],
                },
                AllowedPages: [],
              },
            },
          } as SelectCompanyInterface);
        })
      );
  };

  logout = (sendMessage: boolean) => {
    this.storeService.panelSettings$.set(null);
    this.storeService.brands$.set([]);
    this.storeService.categories$.set([]);
    this.storeService.activeProviders$.set([]);
    this.storeService.priceTemplate$.set([]);
    this.storeService.shippingTemplate$.set([]);
    this.storeService.moduleMergeList$.set(null);
    this.storeService.company$.set(undefined);
    this.storeService.remainingTime.set(0);
    this.token$.set(undefined);
    this.localStorageService.clear();

    this.router.navigate(['/signin']);
    if (sendMessage) {
      this.authChannel.postMessage(undefined);
    }
  };

  getToken = () => this.localStorageService.getItem('CurrentUser');

  setToken = (token: string, sendMessage: boolean) => {
    this.localStorageService.setItem('CurrentUser', token);
    this.token$.set(token);
    if (sendMessage) {
      this.authChannel.postMessage(token);
    }
  };
}
